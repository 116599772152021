
<template>
  <div class="Report_I">
    <div class="headNavs">
      <div class="headTilte">销售报表</div>
      <div class="headDetails">说明：统计年度，月度，每日销售流水数据，统计范围包含每个站点/分公司,数据可视化且永久保存。</div>
    </div>

    <div class="searchNav">
      <div class="NavLi">
          <div class="NavLiName">站点：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.site_id" placeholder="请选择站点">
              <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"> </el-option>
            </el-select>
          </div>
      </div>
      <div class="NavLi">
          <div class="NavLiName">时间：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="Months" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
          </div>
      </div>
      <div class="NavLi">
        <div @click="SetUp()" style="cursor: pointer;"><i class="el-icon-s-tools"></i></div>
        <div class="NavLiQuery" style="margin-left:24px" @click="BtnSearch()">查询</div>
        <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
      </div>
      <div class="NavLI"></div>
      <div class="NavLI"></div>
      <div class="NavLI"></div>
    </div>

    <!-- 导航栏 -->
    <div class="saleNav">
        <div class="navs">
            <div class="navsTop">
                <div class="navsTopLeft">
                    <div>年度累计统计</div>
                    <div><span class="divspan">{{Year.amount}}</span> 元</div>
                    <div>月度完成比  {{Year.ratio}}%</div>
                    <div>月环比增长  {{Year.points}}%</div>
                </div>
                <div class="navsTopRight">
                  <div id="myChart1"></div>
                </div>
            </div>
        </div>
        <div class="navs">
            <div class="navsTop">
                <div class="navsTopLeft">
                    <div>当月累计统计</div>
                    <div><span class="divspan">{{Month.amount}}</span> 元</div>
                    <div>月度完成比  {{Month.ratio}}%</div>
                    <div>月环比增长  {{Month.points}}%</div>
                </div>
                <div class="navsTopRight">
                  <div id="myChart2"></div>
                </div>
            </div>
        </div>
        <div class="navs">
            <div class="navsTop">
                <div class="navsTopLeft">
                    <div>当日累计统计</div>
                    <div><span class="divspan">{{Day.amount}}</span> 元</div>
                    <div>月度完成比 {{Day.ratio}}%</div>
                    <div>月环比增长 {{Day.points}}%</div>
                </div>
                <div class="navsTopRight">
                  <div id="myChart3"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnExport()"><i class="el-icon-download"></i>导出</div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column align="center" prop="date" label="时间"></el-table-column>
        <el-table-column align="center" prop="username" label="员工"></el-table-column>
        <el-table-column align="center" prop="day_amount" label="当日业绩"></el-table-column>
        <el-table-column align="center" prop="day_points" label="同昨日比"></el-table-column>
        <el-table-column align="center" prop="today_num" label="当日订单数"></el-table-column>
        <el-table-column align="center" prop="" label="本月完成比" width="300">
          <template #default="scope">
            <div style="width:80%">
              <el-progress :percentage="scope.row.month_ratio"></el-progress>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange1"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="show"  v-if="addPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew" style="width:780px">
        <div class="EditNewTop">
          <div>任务设置</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm" style="padding:0 24px;">
          <el-form-item class="EditNewFormBtns ggg">
            <div class="EditNewFormItemName" @click="ShowYear(1)">创建年度计划</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" v-for="(item,index) in YearList" :key="index" @click="YearClick(1,item)">
            <div class="ItemTube" :class="TaskId1 == index ? 'addddd':''">
              <div>{{item.year}}年度计划</div>
              <el-dropdown trigger="click">
                <i class="el-icon-more"></i>
                <template #dropdown>
                  <el-dropdown-menu class="menu">
                    <el-dropdown-item class="menu__item" @click="HandleEdit(1,item)">修改</el-dropdown-item>
                    <el-dropdown-item class="menu__item" @click="HandleDel(1,item)">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns MM">
            <div class="EditNewFormItemName kkkk">年度任务计划（单位：万人民币）</div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemIpt" style="width: 80%;">
              <el-input v-model="Task" disabled placeholder=""></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns MM">
            <div class="EditNewFormItemName kkkk">月度任务计划（单位：万人民币）</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" v-for="(item,index) in MonthList" :key="index" @click="YearClick(2,item)">
            <div class="ItemTube" :class="TaskId2 == index ? 'addddd':''">
              <div>{{item.month}}月：{{item.money}}</div>
              <el-dropdown trigger="click">
                <i class="el-icon-more"></i>
                <template #dropdown>
                  <el-dropdown-menu class="menu">
                    <el-dropdown-item class="menu__item" @click="HandleEdit(2,item)">修改</el-dropdown-item>
                    <el-dropdown-item class="menu__item" @click="HandleDel(2,item)">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns ggg">
            <div class="EditNewFormItemName" @click="ShowYear(2)">创建月度计划</div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns MM">
            <div class="EditNewFormItemName kkkk">个人月度任务计划（单位：万人民币）</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" v-for="item in PersonList" :key="item.id">
            <div class="ItemTube">
              <div>{{item.business?item.business.username:''}}：{{item.business?item.money:''}}</div>
              <el-dropdown trigger="click">
                <i class="el-icon-more"></i>
                <template #dropdown>
                  <el-dropdown-menu class="menu">
                    <el-dropdown-item class="menu__item" @click="HandleEdit(3,item)">修改</el-dropdown-item>
                    <el-dropdown-item class="menu__item" @click="HandleDel(3,item)">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>


    <el-dialog :title="titleMath" v-model="editVisible1" width="27%" >
      <el-form label-width="70px" v-if="isEdit == 1">
        <el-form-item label="创建时间">
          <el-date-picker v-model="Row.year" type="year" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="选择站点">
          <el-select v-model="Row.site_id" placeholder="请选择站点" > 
            <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name"  :value="item.site_id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年度任务">
          <el-input v-model="Row.money" placeholder="请选择年度任务（万元）" ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="FormBtnsQuery" v-if="isAdd == 1" @click="BtnAdd(1)">创建</div>
          <div class="FormBtnsQuery" v-if="isAdd == 2" @click="BtnEdit(1)">保存</div>
          <div class="FormBtnsClose" @click="editVisible1 = false">取消</div>
        </el-form-item>
      </el-form>
      <el-form label-width="70px" v-if="isEdit == 2">
        <el-form-item label="创建时间">
          <el-date-picker v-model="Row1.year" type="month" placeholder="选择日期" :clearable="false" ></el-date-picker>
        </el-form-item>
        <el-form-item label="服务商">
          <el-select v-model="Row1.business_ids" multiple  placeholder="选择服务商" >
            <el-option v-for="item in BusinessList" :key="item.id" :label="item.username" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="月度任务">
          <el-input v-model="Row1.money" placeholder="请选择月度任务（万元）" ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="FormBtnsQuery" v-if="isAdd == 3" @click="BtnAdd(2)">创建</div>
          <div class="FormBtnsQuery" v-if="isAdd == 4" @click="BtnEdit(2)">保存</div>
          <div class="FormBtnsClose" @click="editVisible1 = false">取消</div>
        </el-form-item>
      </el-form>
      <el-form label-width="70px" v-if="isEdit == 3">
        <el-form-item label="创建时间">
          <el-date-picker v-model="Row1.year" type="month" placeholder="选择日期" disabled :clearable="false" ></el-date-picker>
        </el-form-item>
        <el-form-item label="月度任务">
          <el-input v-model="Row1.money" placeholder="请选择月度任务（万元）" ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="FormBtnsQuery" v-if="isAdd == 5" @click="BtnEdit(3)">保存</div>
          <div class="FormBtnsClose" @click="editVisible1 = false">取消</div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script> 
import axios from 'axios'
import * as echarts from 'echarts'
export default {
  name: "basetable",
  data() {
    return {
      search:{
        site_id:'',
        year:'',
        month:'',
        day:'',
      },
      Row:{site_id:'',year:'',money:''},
      Row1:{site_id:'',year:'',money:'',business_ids:'',month:''},
      page: {currentPage: 1,pageSize: 10},
      total:'',
      Task:'',
      TaskId1:0,
      TaskId2:0,
      Months:'',
      titleMath:'',
      isAdd:'',
      isEdit:'',
      tableData:[],
      SiteList:[],
      Year:[],
      Month:[],
      Day:[],
      Date:[],
      YearList:[],
      MonthList:[],
      PersonList:[],
      BusinessList:[],
      addPanel:false,
      editVisible1:false
    };
  },
  mounted(){
    this.getSiteList()
    this.getTongji()
    this.loadTable()
    this.getMyChart1()
    this.getMyChart2()
    this.getMyChart3()



    this.getYearList()
   
  },
  methods: {
    ShowYear(e){ //创建年度计划
      if(e == 1){
        this.editVisible1 = true
        this.titleMath = '创建年度计划'
        this.Row = {}
        this.isAdd = 1
        this.isEdit = 1
      }else if(e == 2){
        this.editVisible1 = true
        this.titleMath = '创建月度计划'
        this.Row = {}
        this.isAdd = 3
        this.isEdit = 2
      }
    },
    HandleEdit(e,data){
      if(e == 1){
        this.isAdd = 2
        this.isEdit = 1
        this.editVisible1 = true
        this.titleMath = '编辑年度计划'
        this.Row.site_id = data.site_id
        this.Row.money = data.money
        this.Row.year = data.year.toString()
      }else if(e == 2){
        this.isAdd = 4
        this.isEdit = 2
        this.editVisible1 = true
        this.titleMath = '编辑月度计划'
        this.Row1.business_ids = data.business_ids
        this.Row1.money = data.money
        this.Row1.year = data.year.toString() +  '-' +  data.month.toString() 
      }else if(e == 3){
        this.isAdd = 5
        this.isEdit = 3
        this.editVisible1 = true
        this.titleMath = '编辑个人月度计划'
        this.Row1.site_id = data.site_id
        this.Row1.business_ids = data.business_id
        this.Row1.month = data.month
        this.Row1.money = data.money
        this.Row1.year = data.year.toString() +  '-' +  data.month.toString() 
      }
    },
    BtnAdd(e){
      var that = this
      let bcc = {}
      if(e == 1){  //创建年度计划
        var time = that.formatDate2(that.Row.year)
        axios({
          method: 'POST',
          url: '/admin/workbench/assignment/addYear',
          data: {
            site_id:that.Row.site_id,
            year:time,
            money:that.Row.money
          },
        }).then(function (res) {
          that.editVisible1 = false
          that.TaskId1 = 0
          that.getYearList()
        })
      }else if(e == 2){ //创建月度计划 
        var site_id = that.YearList[that.TaskId1].site_id
        var time = that.formatDate(that.Row1.year)
        var arr = time.split('-')
        bcc.year = arr[0]
        bcc.site_id = site_id
        axios({
          method: 'POST',
          url: '/admin/workbench/assignment/addMonth',
          data: {
            site_id:site_id,
            year:arr[0],
            month:arr[1],
            business_ids:that.Row1.business_ids,
            money:that.Row1.money
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editVisible1 = false
            that.getMonthList(bcc)
          }
        })
      }
    },
    BtnEdit(e){
      var that = this
      let bcc = {}
      if(e == 1){
        var time = that.formatDate2(that.Row.year)
        axios({
          method: 'PUT',
          url: '/admin/workbench/assignment/editYear',
          data: {
            site_id:that.Row.site_id,
            year:time,
            money:that.Row.money
          },
        }).then(function (res) {
          if(res.data.code == 0){
            that.$message.warning(res.data.msg)
          }else{
            that.$message.success(res.data.msg)
            that.editVisible1 = false
            that.TaskId1 = 0
            that.getYearList()
          }
        })
      }else if(e == 2){
        var site_id = that.YearList[that.TaskId1].site_id
        var time = that.formatDate(that.Row1.year)
        var arr = time.split('-')
        bcc.year = arr[0]
        bcc.site_id = site_id
        axios({
          method: 'PUT',
          url: '/admin/workbench/assignment/editMonth',
          data: {
            site_id:site_id,
            year:arr[0],
            month:arr[1],
            business_ids:that.Row1.business_ids,
            money:that.Row1.money
          },
        }).then(function (res) {
          if(res.data.code == 0){
            that.$message.warning(res.data.msg)
          }else{
            that.$message.success(res.data.msg)
            that.editVisible1 = false
            that.TaskId2 = 0
            that.getMonthList(bcc)
          }
        })
      }else if(e == 3){
        var arr = this.Row1.year.split('-')
        bcc.year = arr[0]
        bcc.month = arr[1]
        bcc.site_id = that.Row1.site_id
        axios({
          method: 'PUT',
          url: '/admin/workbench/assignment/editPerson',
          data: {
            site_id:that.Row1.site_id,
            year:arr[0],
            month:arr[1],
            business_id:that.Row1.business_ids,
            money:that.Row1.money
          },
        }).then(function (res) {
          if(res.data.code == 0){
            that.$message.warning(res.data.msg)
          }else{
            that.$message.success(res.data.msg)
            that.editVisible1 = false
            that.getPersonList(bcc)
          }
        })
      }
    },
    HandleDel(e, data){
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        if(e == 1){  
          axios({
            method: 'DELETE',
            url: '/admin/workbench/assignment/delYear',
            data: {
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.TaskId1 = 0
              that.getYearList();
            }
          })
        }else if(e == 2){
          axios({
            method: 'DELETE',
            url: '/admin/workbench/assignment/delMonth',
            data: {
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.TaskId2 = 0
              that.getMonthList(data)
            }
          })
        }else if(e == 3){
          axios({
            method: 'DELETE',
            url: '/admin/workbench/assignment/delPerson',
            data: {
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.getPersonList(data)
            }
          })
        }
      })
    },
    getYearList(){  //年度计划列表
      var that = this
      axios.get('/admin/workbench/assignment/yearList',{
      }).then(function (res) {
        that.YearList = res.data.data
        that.Task = res.data.data[0].money
        var arr = that.YearList[0]
        that.getMonthList(arr)
      })
    },
    getMonthList(data){  //月度计划列表
      var that = this
      axios({
        method: 'POST',
        url: '/admin/workbench/assignment/monthList',
        data: {
          site_id:data.site_id,
          year:data.year
        },
      }).then(function (res) {
        that.MonthList = res.data.data
        var arr = that.MonthList[0]
        that.getPersonList(arr)
      })
    },
    getPersonList(data){  //个人计划列表
      var that = this
      axios({
        method: 'POST',
        url: '/admin/workbench/assignment/personList',
        data: {
          site_id:data.site_id,
          year:data.year,
          month:data.month
        },
      }).then(function (res) {
        that.PersonList = res.data.data
      })
    },
    YearClick(e,data){  
      var that = this
      if(e == 1){
        that.Task = data.money
        var index = that.YearList.findIndex(obj => obj.id === data.id)
        that.TaskId1 = index
        axios({
          method: 'POST',
          url: '/admin/workbench/assignment/monthList',
          data: {
            site_id:data.site_id,
            year:data.year
          },
        }).then(function (res) {
          that.MonthList = res.data.data
          if(that.MonthList != ''){
            var arr = that.MonthList[0]
            that.getPersonList(arr)
          }
        })
      }else if(e == 2){
        var index = that.MonthList.findIndex(obj => obj.id === data.id)
        that.TaskId2 = index
        axios({
          method: 'POST',
          url: '/admin/workbench/assignment/personList',
          data: {
            site_id:data.site_id,
            year:data.year,
            month:data.month
          },
        }).then(function (res) {
          that.PersonList = res.data.data
        })
      }
    },
    loadTable() {  // 获取列表数据 
      var that = this
      var timestamp = Date.parse(new Date());
      var time = that.formatDate(timestamp)
      var arr = time.split('-')
      axios({
        method: 'POST',
        url: '/admin/workbench/assignment/salelist',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: {
            site_id:1,
            year:arr[0],
            month:arr[1],
            day:arr[2]
          },
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    loadTable1() {  
      var that = this
      var arr = []
      if(that.Months != ''){
        var time = that.formatDate(that.Months)
        arr = time.split('-')
      }
      axios({
        method: 'POST',
        url: '/admin/workbench/assignment/salelist',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: {
            site_id:that.search.site_id,
            year:arr[0],
            month:arr[1],
            day:arr[2]
          },
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    getTongji1(){ 
      var that = this
      var arr = []
      if(that.Months != ''){
        var time = that.formatDate(that.Months)
        arr = time.split('-')
      }
      axios({
        method: 'post',
        url: '/admin/workbench/assignment/tongji',    
        data: {
          site_id:that.search.site_id,
          year:arr[0],
          month:arr[1],
          day:arr[2]
        },
      }).then(function (res) {
        that.Year = res.data.data.year
        that.Month = res.data.data.month
        that.Day = res.data.data.day
        that.Date = res.data.data.date
      })  
    },
    getTongji(){ //统计数据
      var that = this
      var timestamp = Date.parse(new Date());
      var time = that.formatDate(timestamp)
      var arr = time.split('-')
      axios({
        method: 'post',
        url: '/admin/workbench/assignment/tongji',    
        data: {
          site_id:1,
          year:arr[0],
          month:arr[1],
          day:arr[2]
        },
      }).then(function (res) {
        that.Year = res.data.data.year
        that.Month = res.data.data.month
        that.Day = res.data.data.day
        that.Date = res.data.data.date
      })  
    },
    getSiteList(){ // 站点列表   
      var that = this
      axios.get('/admin/ajax/siteList',{   
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else { 
          that.SiteList = res.data.data
        }
      }) 
      axios.get('/admin/ajax/businessList',{   // 服务商
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.BusinessList = res.data.data
        }
      }) 
    },
    BtnSearch(e){  //搜索
      var that = this
      if(e == false){
        that.search.site_id = ''
        that.Months = ''
        that.loadTable()
        that.getTongji()
      }else{
        that.loadTable1()
        that.getTongji1()
      }
    },
    getMyChart3(){
      let myChart = echarts.init(document.getElementById('myChart3'))
      myChart.setOption({
        color: ['#1890FF'],
        xAxis: {
            type: 'category',
            show:false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        },
        yAxis: {type: 'value',show:false},
        series: [{data: [120, 200, 150, 80, 70, 110, 130],type: 'bar'}]
      })
    },
    getMyChart2(){
      let myChart = echarts.init(document.getElementById('myChart2'))
      myChart.setOption({
        tooltip: {trigger: 'item'},
        series: [{
          name: '访问来源',
          hoverAnimation:false,
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
              show: false,
              position: 'center',
              normal: {position: 'inner',show : false}
          },
          emphasis: {
              label: {show: false,fontSize: '40',fontWeight: 'bold'}
          },
          labelLine: {show: false},
          data: [
              {value: 1048, name: '搜索引擎'},
              {value: 735, name: '直接访问'},
              {value: 580, name: '邮件营销'},
              {value: 300, name: '视频广告'},
          ]}
        ]
      })
    },
    getMyChart1(){
      let myChart = echarts.init(document.getElementById('myChart1'))
      myChart.setOption({
        xAxis: {
          type: 'category',
          show:false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          "splitLine": {"show": false}
        },
        yAxis: {
          type: 'value',
          splitLine: {lineStyle: {color: ["#fff"]}},
          show:false,
          "splitLine": {"show": false}//网格线
        },
        series: [{
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line',
          showSymbol: false,
          hoverAnimation: false,
          itemStyle:{normal:{lineStyle:{ color:'#1890FF'  }}}//折线颜色
        }]
      })
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
    formatDate2(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y
    },
    Close(){
      this.addPanel = false
    },
    SetUp(){
      this.addPanel = true
    }
  },
};
</script>

<style scoped>   
.NavLi{
  width: 33%;
}
.NavLiIpt{
  width: 70%;
}
.NavLiIpt /deep/ .el-date-editor.el-input{
  width: 100% !important;
}
.saleNav{
  margin: 20px 20px;
  display: flex;
  justify-content: space-between;
}
.navs{
  width: 29%;
  height: 171px;
  background-color: #fff;
  border-radius: 6px;
  padding: 1.6%;
}
.navs:nth-child(2){
  width: 25%;
}
.navs:nth-child(1){
  width: 33%;
}
.navsTop{
  display: flex;
  justify-content: space-between;
}
.navsTopLeft .divspan{
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FF4D4F;
  margin-top: 10px;
}
.navsTopLeft div{
  margin-top: 15px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
}
.navsTopLeft div:nth-child(3){
  margin-top: 65px;
}
.navsTopLeft div:nth-child(1){
  margin-top: 0;
  color: #262626
}
.navsTopRight{
  width: 65%;
}
#myChart2, #myChart3,#myChart1{
  width: 90%;
  height: 100%;
  margin-right: 50px;
  text-align: right;
}
.ggg{
  margin-bottom: 4px;
  color: #1890FF;
  cursor: pointer;
  font-weight: bold;
}
.ItemTube{
  width: 90%;
  height: 32px;
  line-height: 32px;
  background: #f5f7fa;
  border-radius: 2px;
  text-indent: 1.5em;
  cursor: pointer;
}
.ItemTube /deep/ .el-dropdown{
  position: absolute;
  top: 0px;
  right: 28px;
}
.el-icon-more{
  cursor: pointer;
  margin-left: 8px;
  position: relative;
  transform: rotate(90deg);
}
.menu{
  width: 70px !important;
  margin-top: 0;
}
.menu__item{
  margin-bottom: 0;
}
.addddd{
  background: #E8E8E8!important;
  border-radius: 4px;
}
.kkkk{
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
}
.MM{
  margin-bottom: 6px;
}
</style>
